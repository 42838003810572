import React from "react";
import { graphql, Link } from "gatsby";
import { RichText, Date } from "prismic-reactjs";
import styled from "@emotion/styled";
import Layout from "../components/layout/index";
import Categories from "../components/Listing/Categories";
import SEO from "../components/seo";

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
`;
const StyledLink = styled(Link)`
  font-size: clamp(1.3rem, 2.5vw, 1.5rem);
  color: #000;
  font-style: normal;
  text-decoration: none;
  :hover {
    color: #fbb400;
  }
`;

const ImageContainer = styled.div`
  .block-img {
    padding: 2em;
    img {
      border: 1px solid rgba(64, 87, 109, 0.07);
      height: 100%;
      width: 100%;
      border-radius: 4px;
    }
  }
`;

const DefaultImage = ({ dataImage }) => (
  <ImageContainer>
    <div className="block-img">
      <img
        loading="lazy"
        src={dataImage.primary.image_banner.url}
        alt={dataImage.primary.image_banner.alt}
      />
    </div>
  </ImageContainer>
);

const PostSlices = ({ slices }) => {
  const dataImage = slices.find(
    (slice) => slice.slice_type === "banner_with_caption"
  );

  return (
    <>{dataImage !== undefined && <DefaultImage dataImage={dataImage} />}</>
  );
};

const PostBody = ({ bodyImage }) => {
  return <PostSlices slices={bodyImage} />;
};

const Category = (props) => {
  const {
    data: { posts },
  } = props;
  return (
    <Layout>
      <SEO title="Blog Category" />
      <div
        style={{
          maxWidth: 1200,
          margin: "0 auto",
        }}
      >
        <>
          <div
            style={{
              padding: "1em 0.4em",
              fontSize: "2rem",
              fontWeight: 300,
              borderRadius: 4,
              textTransform: "uppercase",
            }}
          >
            <strong style={{ color: "#fbb400", marginRight: "0.4em" }}>
              Blog
            </strong>
            {props.pageContext.name.replace("-", " ")}
          </div>
        </>
        <>
          {posts.nodes.length !== 0 &&
            posts.nodes.map((object) => (
              <CardWrapper key={object.id}>
                <PostBody bodyImage={object.data.body} />
                <div
                  style={{
                    padding: "2em",
                  }}
                >
                  <StyledLink to={`/blog/${object.uid}`}>
                    {RichText.asText(object.data.title.raw).length !== 0 &&
                      RichText.asText(object.data.title.raw)}
                  </StyledLink>
                  {object.data.categories.length !== 0 && (
                    <Categories
                      categories={object.data.categories}
                      style={{ paddingTop: "0.6em" }}
                    />
                  )}
                  <div
                    style={{
                      color: "rgba(117, 117, 117, 1)",
                      padding: "0.3em 0em",
                      fontSize: "0.85rem",
                    }}
                  >
                    <time>
                      {new Intl.DateTimeFormat("en-US", {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                      }).format(Date(object.data.date))}
                    </time>
                  </div>
                </div>
              </CardWrapper>
            ))}
        </>
      </div>
    </Layout>
  );
};

export default Category;

export const query = graphql`
  query ($language: String!, $slug: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    posts: allPrismicPostBlog(
      sort: { fields: [last_publication_date], order: DESC }
      filter: {
        data: {
          categories: {
            elemMatch: {
              category: { lang: { eq: $language }, slug: { eq: $slug } }
            }
          }
        }
      }
    ) {
      nodes {
        id
        uid
        url
        lang
        data {
          title {
            raw
          }
          date
          categories {
            category {
              id
              document {
                ... on PrismicCategory {
                  data {
                    name
                  }
                }
              }
            }
          }
          body {
            ... on PrismicPostBlogBodyBannerWithCaption {
              id
              slice_label
              slice_type
              primary {
                image_banner {
                  alt
                  url
                  dimensions {
                    height
                    width
                  }
                }
                description {
                  raw
                }
              }
            }
          }
        }
      }
    }
  }
`;
